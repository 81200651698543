import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/1.jpg';
// import p2 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/2.jpg';
// import p3 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/3.jpg';
// import p4 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/4.jpg';
// import p5 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/5.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/Literaryclub2023/components';
import { LiterarySidebar } from 'views/Literaryclub2023/components';
import Sidebar2024 from '../ScienceClub/Sidebar2024';
import Scienceclubsidebar2023 from '../ScienceClub/Scienceclubsidebar2023';
import ScienceClubSideBar from '../ScienceClubSideBar';

const GeneralScience2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_url}/home/events-activities/association/ScienceClub/GeneralCCA/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/ScienceClub/GeneralCCA/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/ScienceClub/GeneralCCA/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/ScienceClub/GeneralCCA/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/association/ScienceClub/GeneralCCA/5.webp`;
  

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1.3,
            cols: 2,
        },
       
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                GENERAL SCIENCE CCA 
                                </Typography>
                                <br></br>
                                <Typography variant='h7' align='justify' >
                                Class 3: Vegan power,
                                Class 4: Plant Power Saves the World,
                                Class 5: Significance of spices in Indian Cuisine  
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 3-5  &  Date: 12 to 14 August 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The General Science CCA, held in the third week of August saw an active and enthusiastic participation by the students of Classes 3-5.
                                <br></br>
                                Students of Class 3 presented a salad made of edible parts of the plant highlighting it’s nutritive value and health benefits. They were confident and well prepared for the event thereby showcasing their public speaking skills.
                                <br></br>
                                Students of Class 4 created a collage using dried parts of different plants and explained the medicinal benefits of plants glorifying Ayurveda.
                                <br></br>
                                Students of Class 5 made a collage using real spices representing different parts of plants. They explained the medicinal benefits of spices represented in their collage based on the theme, “Significance of spices in Indian Cuisine” along with a recipe.
                                <br></br>
                                This activity inspired the students to learn from each other and improved their knowledge on plants and their products. Each group displayed their unique style and perspective, making this competition a diverse celebration of talent.
                                </Typography>
                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "Language is at its best when thoughts and emotions are expressed through words and actions."

                                <br />
                            </Typography> */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                                    <Sidebar2024/>
                        </Box>
                        <Box marginBottom={4}>
                            <ScienceClubSideBar/>
                            
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default GeneralScience2024;